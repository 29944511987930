<template>
  <v-row align="center">
    <v-col
      class="d-flex"
      cols="6"
    >
      <v-select
        v-model="type"
        :items="types"
        item-text="label"
        item-value="name"
        label="Zeitraum"
        return-object
        @input="onInput"
      />
    </v-col>
    <v-col
      v-if="type !== null"
      class="d-flex"
      cols="6"
    >
      <v-select
        v-model="year"
        :items="years"
        label="Jahr"
        @input="onInput"
      />
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'TimeRangeSelect',
  props: {
    value: String
  },
  data: () => ({
    type: null,
    types: [
      {label: 'Januar', suffix: '-01'},
      {label: 'Februar', suffix: '-02'},
      {label: 'März', suffix: '-03'},
      {label: 'April', suffix: '-04'},
      {label: 'Mai', suffix: '-05'},
      {label: 'Juni', suffix: '-06'},
      {label: 'Juli', suffix: '-07'},
      {label: 'August', suffix: '-08'},
      {label: 'September', suffix: '-09'},
      {label: 'Oktober', suffix: '-10'},
      {label: 'November', suffix: '-11'},
      {label: 'Dezember', suffix: '-12'},
      {label: '1. Quartal', suffix: '-q1'},
      {label: '2. Quartal', suffix: '-q2'},
      {label: '3. Quartal', suffix: '-q3'},
      {label: '4. Quartal', suffix: '-q4'},
      {label: '1. Halbjahr', suffix: '-h1'},
      {label: '2. Halbjahr', suffix: '-h2'},
      {label: 'Jahr', suffix: ''},
    ],
    year: new Date().getFullYear(),
    years: _.range(new Date().getFullYear(), 2003, -1),
  }),
  mounted() {
    if (this.value) {
      for (let type of this.types) {
        if (this.value.endsWith(type.suffix)) {
          this.type = type
          this.year = parseInt(this.value.substring(0, 4))
          break
        }
      }
    }
  },
  methods: {
    onInput() {
      if (this.type !== null && this.year !== null) {
        this.$emit('input', '' + this.year + this.type.suffix)
      }
    }
  },
}
</script>

<style scoped>

</style>