<template>
  <v-container>
    <h1>Umfrage Zugangscodes</h1>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="panel" focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Neue Zugangscodes generieren</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Pending v-model="scopePromise">
                <div>
                  <h3>In welchen Kliniken/Abteilungen sollen die Zugangscodes ausgegeben werden?</h3>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="selectedClients"
                        :disabled="pending"
                        :items="scope.clients"
                        item-text="label"
                        return-object
                        label="Klinik"
                        :multiple="true"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="selectedIndications"
                        :disabled="pending"
                        :items="availableIndications"
                        item-text="name_long"
                        return-object
                        label="Abteilungen"
                        :multiple="true"
                      />
                    </v-col>
                  </v-row>
                  <h3>In welchem Zeitraum sollen die Zugangscodes ausgegeben werden?</h3>
                  <p>
                    Optionale Angaben um die Auswahl des Entlasszeitpunkts einzuschränken.
                    Standardmäßig stehen der aktuelle und die beiden vorangegangenen Monate zur Auswahl.
                  </p>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="minMenu"
                        v-model="minMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="selectedMinMonth"
                            label="Frühestes Entlassdatum"
                            prepend-icon="mdi-calendar-start"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-card color="primary">
                          <v-date-picker
                            v-model="selectedMinMonth"
                            type="month"
                            locale="de"
                            no-title
                            scrollable
                            color="primary"
                            header-color="primary"
                            :elevation="0"
                          />
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="primary"
                              tile
                              :elevation="0"
                              @click="$refs.minMenu.save(selectedMinMonth)"
                            >
                              OK
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="maxMenu"
                        v-model="maxMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="selectedMaxMonth"
                            label="Spätestes Entlassdatum"
                            prepend-icon="mdi-calendar-end"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-card color="primary">
                          <v-date-picker
                            v-model="selectedMaxMonth"
                            type="month"
                            locale="de"
                            no-title
                            scrollable
                            color="primary"
                            header-color="primary"
                          />
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="primary"
                              tile
                              :elevation="0"
                              @click="$refs.maxMenu.save(selectedMaxMonth)"
                            >
                              OK
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <h3>Wie viele Tage soll der Patient Zeit haben die Umfrage zu bearbeiten, sobald er begonnen hat?</h3>
                  <v-row>
                    <v-col cols="12">
                      <v-slider
                        v-model="selectedTimespan"
                        :disabled="pending"
                        label="Tage"
                        thumb-label="always"
                        min="1"
                        max="60"
                        class="mt-8"
                      />
                    </v-col>
                  </v-row>

                  <h3>Wie viele Zugangscodes sollen generiert werden?</h3>
                  <v-row>
                    <v-col cols="12">
                      <v-slider
                        v-model="selectedCount"
                        :disabled="pending"
                        label="Anzahl"
                        thumb-label="always"
                        min="1"
                        max="500"
                        class="mt-8"
                      />
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-btn
                      block
                      color="primary"
                      :loading="pending"
                      :disabled="pending || selectedClients.length === 0 || selectedIndications.length === 0"
                      @click="submit"
                    >
                      Zugangscodes generieren
                    </v-btn>
                  </v-card-actions>
                </div>
              </Pending>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <Pending v-model="batchesPromise">
      <v-row>
        <v-col
          v-for="batch in batches.batches"
          :key="batch.batch"
          cols="12"
          md="6"
        >
          <SurveyTokenBatchCard :batch="batch" />
        </v-col>
      </v-row>
    </Pending>
  </v-container>
</template>

<script>
import axios from 'axios'
import SurveyTokenBatchCard from '@/components/SurveyTokenBatchCard'

export default {
  name: 'SurveyTokenGeneration',
  components: {
    SurveyTokenBatchCard
  },
  data: () => ({
    panel: null,
    pending: false,
    scopePromise: null,
    batchesPromise: null,

    batches: [],

    surveys: [],
    clients: [],

    surveysById: {},
    clientsById: {},
    indicationsById: {},
    accountsById: {},

    scope: {},
    selectedSurvey: null,
    selectedTimespan: 7,
    selectedClients: [],
    selectedIndications: [],
    selectedCount: 10,
    selectedMinMonth: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`,
    selectedMaxMonth: null,

    minMenu: false,
    maxMenu: false,
  }),
  computed: {
    availableIndications() {
      const indicationIds = []
      const indications = []
      this.selectedClients.forEach(client => {
        client.surveys.forEach(survey => {
          if (!indicationIds.includes(survey.indication.id)) {
            indicationIds.push(survey.indication.id)
            indications.push(survey.indication)
          }
        })
      })
      return indications
    },
  },
  watch: {
    availableIndications(val) {
      const availableIndicationIds = []
      val.forEach(indication => {
        availableIndicationIds.push(indication.id)
      })
      const c = [...this.selectedIndications]
      c.forEach(indication => {
        if (!availableIndicationIds.includes(indication.id)) {
          this.selectedIndications.splice(this.selectedIndications.indexOf(indication), 1)
        }
      })
    },
  },
  created() {
    this.fetchBatches()
    this.scopePromise = axios.get(this.$store.state.apiPath + '/survey-access/scope')
    this.scopePromise.then(response => {
      this.scope = response.data
      if (this.scope.clients.length === 1) this.selectedClients = [this.scope.clients[0]]
      if (this.availableIndications.length === 1) this.selectedIndications = [this.availableIndications[0]]
    })
  },
  methods: {
    fetchBatches() {
      this.batchesPromise = axios.get(this.$store.state.apiPath + '/survey-access/batches')
      this.batchesPromise.then(response => {
        this.batches = response.data
        this.batches.batches.forEach(this.parseBatch)
      })
    },
    submit() {
      this.pending = true
          axios
            .post(this.$store.state.apiPath + '/survey-access/tokens', {
              available_clients: this.selectedClients.map(i => i.id),
              available_indications: this.selectedIndications.map(i => i.id),
              count: this.selectedCount,
              discharge_month_min: this.selectedMinMonth,
              discharge_month_max: this.selectedMaxMonth,
              access_days: this.selectedTimespan,
              activate: false,
            })
            .then(() => {
              this.fetchBatches()
              this.panel = null
            })
            .finally(() => {this.pending = false})
    },
    parseBatch(batch) {
      batch.created_by = this.batches.accounts[batch.created_by]
      batch.available_indications = this.resolveIds(batch.available_indications, this.batches.indications)
      batch.available_clients = this.resolveIds(batch.available_clients, this.batches.clients)
      batch.created = new Date(batch.created)
      return batch
    },
    resolveIds(arr, map) {
      const res = []
      arr.forEach(id => {
        res.push(map[id])
      })
      return res
    },
  }
}
</script>

<style scoped>

</style>