<template>
  <v-card>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Batch ID</td>
              <td>{{ batch.batch }}</td>
            </tr>
            <tr>
              <td>Erzeugt am</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ batch.created.toLocaleDateString('de') }}
                    </span>
                  </template>
                  <span>{{ batch.created.toLocaleString('de') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr v-if="batch.available_clients.length === 1">
              <td>Klinik</td>
              <td>{{ batch.available_clients[0].label }}</td>
            </tr>
            <tr v-else>
              <td>Kliniken</td>
              <td>
                <ul>
                  <li v-for="(client, i) in batch.available_clients" :key="i">
                    {{ client.label }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr v-if="batch.available_indications.length === 1">
              <td>Indikation</td>
              <td>{{ batch.available_indications[0].name_long }}</td>
            </tr>
            <tr v-else>
              <td>Indikationen</td>
              <td>
                <ul>
                  <li v-for="(indication, i) in batch.available_indications" :key="i">{{ indication.name_long }}</li>
                </ul>
              </td>
            </tr>
            <tr v-if="batch.discharge_month_min || batch.discharge_month_max">
              <td>Entlasszeitpukte</td>
              <td>
                <span v-if="batch.discharge_month_min">{{ batch.discharge_month_max ? 'von' : 'ab' }} {{ batch.discharge_month_min }}</span>
                <span v-if="batch.discharge_month_max"> bis {{ batch.discharge_month_max }}</span>
              </td>
            </tr>
            <tr>
              <td>Bearbeitungszeit</td>
              <td>{{ batch.access_days }} Tage</td>
            </tr>
            <tr>
              <td>Anzahl Codes</td>
              <td>{{ batch.tokens.total }}</td>
            </tr>
            <tr>
              <td>Aktivierte Codes</td>
              <td>{{ batch.tokens.activated }}</td>
            </tr>
            <tr>
              <td>Abgeschlossene Codes</td>
              <td>{{ batch.tokens.finished }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="mt-3"
            block
            small
          >
            Download Codes
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="format in formats"
            :key="format.slug"
            :href="$store.state.apiPath + '/survey-access/batches/' + batch.batch + '/tokens?format=' + format.slug"
            link
          >
            <v-list-item-title>{{ format.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SurveyTokenBatchCard',
  props: {
    batch: Object
  },
  data: () => ({
    formats: [{
      slug: 'pdf',
      label: 'PDF',
    }, {
      slug: 'plain',
      label: 'Klartext',
    }, {
      slug: 'json',
      label: 'JSON',
    }, {
      slug: 'html',
      label: 'HTML',
    }]
  }),
}
</script>

<style scoped>

</style>