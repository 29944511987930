<template>
  <v-container>
    <h1>Entlasszahlen</h1>
    <Pending
      v-model="clientsPromise"
      v-slot="{ result: clients }"
    >
      <v-expansion-panels class="mt-8">
        <v-expansion-panel
          v-for="client in clients"
          :key="client.id"
          @change="load(client)"
        >
          <v-expansion-panel-header>
            <h3>{{ client.label }}</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Pending
              v-model="client.discharges.promise"
              v-slot="{ result: discharges }"
            >
              <v-row v-for="indication in discharges.indications" :key="indication.id">
                <v-col cols="12">
                  <h4>{{ indication.name_long }}</h4>
                </v-col>
                <v-col
                  v-for="(dischargeData, index) in discharges.data[indication.value]"
                  :key="index"
                  md="2"
                  cols="4"
                >
                  <v-text-field
                    :id="`${client.id}_${indication.value}_${dischargeData.year}_${dischargeData.month}`"
                    v-model="dischargeData.editValue"
                    type="text"
                    :rules="rules"
                    :label="months[dischargeData.month] + ' ' + dischargeData.year"
                    :disabled="dischargeData.pending"
                    :loading="dischargeData.pending"
                    :success-messages="dischargeData.successMessages"
                    :error-messages="dischargeData.errorMessages"
                    :messages="dischargeData.messages"
                    @input="resetMessages(dischargeData)"
                    @keyup.enter="save(client, dischargeData, 'enter')"
                    @blur="save(client, dischargeData, 'blur')"
                  />
                </v-col>
              </v-row>
            </Pending>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </Pending>
  </v-container>
</template>

<script>
export default {
  name: 'Discharges',
  data () {
    return {
      clientsPromise: null,
      months: [null,
        'Januar', 'Februar', 'März',
        'April', 'Mai', 'Juni',
        'Juli', 'August', 'September',
        'Oktober', 'November', 'Dezember'
      ],
      rules: [
          v => /^\s*$/.test(v) || /^[0-9]\d*$/.test(v.trim()) || 'Kein gültiger Wert',
      ],
    }
  },
  mounted() {
    this.clientsPromise = new Promise((resolve, reject) => {
      this.axios.get(this.$store.state.apiPath + '/discharges/').then(response => {
        for (let client of response.data) {
          client.discharges = {
            promise: null,
            indications: [],
            data: null,
          }
          client.symbols = []
        }
        resolve(response.data)
      }, reject)
    })
  },
  methods: {
    load(client) {
      if (client.discharges.promise === null) {
        client.discharges.promise = new Promise((resolve, reject) => {
          this.axios.get(this.$store.state.apiPath + '/discharges/' + client.id).then(response => {
            let client_data = response.data.data
            for (let indicationValue of Object.keys(client_data)) {
              for (let dischargeData of client_data[indicationValue]) {
                dischargeData.pending = false
                dischargeData.promise = null
                if (dischargeData.count === null) {
                  dischargeData.editValue = ''
                } else {
                  dischargeData.editValue = dischargeData.count.toString()
                }
                dischargeData.messages = []
                dischargeData.error = false
                dischargeData.errorMessages = []
                dischargeData.success = false
                dischargeData.successMessages = []
                this.resetMessages(dischargeData)
              }
            }
            client.discharges.indications = response.data.indications
            client.discharges.data = client_data
            resolve(client.discharges)
          }, reject)
        })
      }
    },
    resetMessages(dischargeData) {
      dischargeData.error = false
      dischargeData.messages = []
      dischargeData.errorMessages = []
      dischargeData.success = false
      dischargeData.successMessages = []
    },
    save(client, dischargeData, source) {
      if (!dischargeData.pending) {
        this.resetMessages(dischargeData)
        let count = dischargeData.editValue.trim()
        if (!count || /^\s*$/.test(count)) {
          count = null
        } else {
          count = parseInt(count.trim())
        }

        if (count !== null || count >= 0) {
          if (count !== dischargeData.count) {
            dischargeData.pending = true
            dischargeData.promise = this.axios.post(this.$store.state.apiPath + '/discharges/', [{
                client: client.id,
                indication: dischargeData.indication,
                year: dischargeData.year,
                month: dischargeData.month,
                count: count,
            }]).then(() => {
              dischargeData.count = count
              if (count === null) {
                dischargeData.editValue = ''
              } else {
                dischargeData.editValue = count.toString()
              }
              dischargeData.pending = false
              dischargeData.success = true
              dischargeData.successMessages.push('gespeichert')
            }, () => {
              dischargeData.pending = false
              dischargeData.error = true
              dischargeData.errorMessages.push('Fehler beim Speichern')
            })
          } else {
            if (source === 'enter') {
              dischargeData.messages.push('Wert unverändert')
            }
          }
        } else {
          dischargeData.error = true
          dischargeData.errorMessages.push('Ungültiger Wert')
        }
      }
    },
  },
}
</script>

<style lang="scss">

</style>
